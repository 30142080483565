<template>
  <div>
    <a
      href="javascript: void(0)"
      data-toggle="tooltip"
      data-placement="top"
      @click="fnShowModal()"
      ><i class="far fa-edit text-info"></i
    ></a>

    <b-modal
      ref="previousNamesModal"
      size="xl"
      hide-footer
      title="Maiden / Previous / Alias names"
    >
      <div class="d-block">
        <div class="alert alert-danger mb-5 col-12" v-if="errorsMsg.length">
          <p
            class="fs-800 mb-0"
            v-for="(item, index) in errorsMsg"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
        <form v-on:submit.prevent="updatePreviousName">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label class="" for="nameType">Name Type</label>
                <select class="form-control" v-model="userData.name_type">
                  <option
                    :value="item.value"
                    v-for="(item, i) in optionsNameType"
                    :key="i"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="firstName">First name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="First name"
                  v-model="userData.first_name"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="middleName">Middle name</label>
                <input
                  type="text"
                  class="form-control"
                  id="middleName"
                  placeholder="Middle name"
                  v-model="userData.middle_name"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="lastName">Family name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  placeholder="Family name"
                  v-model="userData.last_name"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label class="">Date of change</label>
                <input
                  class="form-control"
                  type="date"
                  autocomplete="off_date_of_change"
                  placeholder=" "
                  v-model="userData.date_of_change"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group" v-if="userData.first_name == ''">
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="No"
                  unchecked-value="Yes"
                  v-model="userData.have_a_first_name"
                >
                  Does not have a given name
                </b-form-checkbox>
              </div>
              <div class="form-group" v-if="userData.middle_name == ''">
                <b-form-checkbox
                  id="checkbox-2"
                  name="checkbox-2"
                  value="No"
                  unchecked-value="Yes"
                  v-model="userData.have_a_middle_name"
                >
                  Does not have a middle name
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">Save</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as policeCheckService from "../../services/policeCheck.service";
import * as chkBankruptcyRecordService from "../../services/chkBankruptcyRecord.service";

export default {
  name: "ModalPreviousNamesEdit",
  props: [
    "projectId",
    "productId",
    "previous_names_status",
    "propsNameData",
    "propsNameIndex",
    "previous_names",
  ],
  components: {},
  data() {
    return {
      errors: {},
      errorsMsg: [],
      userData: {
        name_type: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_change: "",
        have_a_first_name: "Yes",
        have_a_middle_name: "Yes",
      },
      optionsNameType: [
        { value: "Maiden", text: "Maiden" },
        { value: "Alias", text: "Alias" },
        { value: "Previous", text: "Previous" },
      ],
      optionsNameType2: [
        { value: "Alias", text: "Alias" },
        { value: "Previous", text: "Previous" },
      ],
      objPreviousName: {},
      previous_names_add: [],
    };
  },
  computed: {
    ...mapGetters(["getPoliceCheckSingle"]),
  },
  methods: {
    ...mapActions(["fetchPoliceCheckSingle"]),
    fnShowModal() {
      if (this.previous_names) {
        this.previous_names_add = this.previous_names;
      }
      this.userData = this.propsNameData;
      this.showModal();
    },
    hideModal() {
      this.$refs.previousNamesModal.hide();
    },
    showModal() {
      this.fnMaidenAlreadyTaken();

      this.$refs.previousNamesModal.show();
    },
    fnMaidenAlreadyTaken() {
      let objPreviousName = this.getPoliceCheckSingle.previous_names;

      if (objPreviousName && objPreviousName.length > 0) {
        let newArray = objPreviousName.filter(function (item) {
          return item.name_type === "Maiden";
        });

        if (newArray.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    updatePreviousName: async function () {
      if (this.userData.first_name) {
        this.userData.have_a_first_name = "Yes";
      }
      if (this.userData.middle_name) {
        this.userData.have_a_middle_name = "Yes";
      }
      let obj1 = {
        name_type: this.userData.name_type,
        first_name: this.userData.first_name,
        middle_name: this.userData.middle_name,
        last_name: this.userData.last_name,
        date_of_change: this.userData.date_of_change,
        have_a_first_name: this.userData.have_a_first_name,
        have_a_middle_name: this.userData.have_a_middle_name,
      };

      let obj = {
        previous_names_status: this.previous_names_status,
        previous_names: obj1,
      };

      this.errorsMsg = this.fnCheckValidation(this.userData);

      if (this.errorsMsg.length === 0) {
        if (this.previous_names_add.length > 0) {
          this.previous_names_add.splice(this.propsNameIndex, 1);
        }
        this.previous_names_add.push(obj1);

        try {
          this.errors = {};

          // Police check
          if (this.productId == 1) {
            let obj = {
              previous_names_status: this.previous_names_status,
              previous_names: this.previous_names_add,
            };

            await policeCheckService.addPreviousNames(this.projectId, obj);
            this.fetchPoliceCheckSingle(this.projectId);
          }

          // Bankruptcy Record Check
          if (this.productId == 8) {
            let obj = {
              previous_names_status: this.previous_names_status,
              previous_names: this.previous_names_add,
              status: 1,
            };
            await chkBankruptcyRecordService.addOrUpdate(this.projectId, obj);
            this.$emit("fnCallRefresh");
          }

          this.hideModal();

          messageService.fnToastSuccess("Previous name - Successfully added");

          this.userData = {
            name_type: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            date_of_change: "",
            have_a_first_name: "",
            have_a_middle_name: "",
          };
        } catch (error) {
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;

            case 500:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                error.response.data.message
              );
              break;

            default:
              messageService.fnSweetAlertErrorToast(
                "Previous name",
                "Something wrong"
              );
              break;
          }
        }
      }
    },
    fnCheckValidation(val) {
      let msg = [];

      if (val) {
        if (val.name_type === "") {
          msg.push("Name type is required");
        }
        if (val.last_name === "") {
          msg.push("Family name is required");
        }
        if (val.date_of_change === "") {
          msg.push("Date of name change is required");
        }
        if (
          (val.first_name === "" && val.have_a_first_name === "Yes") ||
          (val.first_name === "" && val.have_a_first_name === "")
        ) {
          msg.push('Please confirm that "Does not have a given name"');
        }
        if (
          (val.middle_name === "" && val.have_a_middle_name === "Yes") ||
          (val.middle_name === "" && val.have_a_middle_name === "")
        ) {
          msg.push('Please confirm that "Does not have a middle name"');
        }
      } else {
        msg.push("Relevant information is required");
      }

      return msg;
    },
  },
};
</script>
